import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
	ResponsiveLayoutResult,
	getComponentResponsiveLayoutCss,
	selectorObjToCss,
	toMediaQuery,
	BreakpointsVariantsToSelectorsMap,
	CompVariants,
} from '@wix/thunderbolt-catharsis'
import { BreakpointRange, BreakpointsData, Component } from '@wix/thunderbolt-becky-types'
import _ from 'lodash'
import { ComponentCssContextValue, StructureComponentCssProps, ComponentsCssProps } from './ComponentsCss.types'

const debounce = (fn: () => void) => {
	let promise: Promise<void> | null = null
	return () => {
		if (!promise) {
			promise = Promise.resolve().then(() => {
				promise = null
				fn()
			})
		}
	}
}

const ComponentCssContext = React.createContext<ComponentCssContextValue>({} as ComponentCssContextValue)

const StructureComponentCss = React.memo(
	({ id, isInRepeater, breakpointsData, ancestorsBreakpointsData }: StructureComponentCssProps) => {
		const { getByCompId, subscribeByCompId } = useContext<ComponentCssContextValue>(ComponentCssContext)

		const data = getByCompId(id)
		const { structure, breakpointsOrder = breakpointsData, responsiveLayout, pinnedLayer, variants } = data

		const [, setTick] = useState(0)
		const forceUpdate = useCallback(() => setTick((tick) => tick + 1), [])

		// eslint-disable-next-line react-hooks/exhaustive-deps
		useEffect(() => subscribeByCompId(id, forceUpdate), [])

		if (!structure) {
			return null
		}

		const breakpointsToSelectorObj = getComponentResponsiveLayoutCss(
			id,
			isInRepeater,
			responsiveLayout,
			pinnedLayer,
			variants,
			breakpointsOrder
		)

		const breakpointsOrderMap = breakpointsOrder?.values ? _.keyBy(breakpointsOrder?.values, 'id') : null
		const myBreakpointsDataMap = { ...ancestorsBreakpointsData, ...breakpointsOrderMap }

		const children = structure.components?.map((childId) => (
			<StructureComponentCss
				key={childId}
				id={childId}
				isInRepeater={isInRepeater || structure.componentType === 'Repeater'}
				breakpointsData={breakpointsOrder}
				ancestorsBreakpointsData={myBreakpointsDataMap}
			/>
		))

		const breakpoints = breakpointsOrder?.values
			? ['default', ...breakpointsOrder.values.map((b) => b.id)]
			: ['default']

		return (
			<React.Fragment>
				{breakpoints.map((breakpointId) => {
					const breakpointCss = breakpointsToSelectorObj[breakpointId]
					if (!breakpointCss) {
						return null
					}
					const currentBreakpointCss = selectorObjToCss(breakpointCss)

					return (
						<style key={breakpointId} data-comp-id={id}>
							{breakpointId === 'default'
								? currentBreakpointCss
								: `${toMediaQuery(
										myBreakpointsDataMap[breakpointId] as BreakpointRange
								  )}{${currentBreakpointCss}}`}
						</style>
					)
				})}
				{children}
			</React.Fragment>
		)
	},
	(prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)

export const ComponentsCss = (props: ComponentsCssProps) => {
	const structureStore = props.megaStore.getChildStore('structure')
	const breakpointsOrderStore = props.megaStore.getChildStore('breakpointsOrder')
	const variantsStore = props.megaStore.getChildStore('variants')
	const responsiveLayoutStore = props.megaStore.getChildStore('responsiveLayout')
	const pinnedLayerStore = props.megaStore.getChildStore('pinnedLayer')

	const updateStore = (partialStore: any) => {
		for (const compId in partialStore) {
			structureStore.updateById(compId, partialStore[compId])
		}
	}
	updateStore(props.structureStore.getEntireStore())
	props.structureStore.subscribeToChanges(updateStore)

	const getByCompId = (compId: string) => ({
		structure: structureStore.getById<Component>(compId),
		breakpointsOrder: breakpointsOrderStore.getById<BreakpointsData | null>(compId),
		variants: variantsStore.getById<CompVariants>(compId),
		responsiveLayout: responsiveLayoutStore.getById<ResponsiveLayoutResult>(compId),
		pinnedLayer: pinnedLayerStore.getById<BreakpointsVariantsToSelectorsMap | null>(compId),
	})

	const stores = [structureStore, breakpointsOrderStore, responsiveLayoutStore, pinnedLayerStore, variantsStore]

	const subscribeByCompId = (compId: string, callback: () => void) => {
		const debounced = debounce(() => props.batchingStrategy.batch(callback))
		const unsubscribes = stores.map((store) => store.subscribeById(compId, debounced))
		return () => unsubscribes.forEach((unsubscribe) => unsubscribe())
	}

	const storesContextValue = { getByCompId, subscribeByCompId }

	return (
		<ComponentCssContext.Provider value={storesContextValue}>
			<StructureComponentCss
				id={props.rootCompId}
				isInRepeater={false}
				breakpointsData={null}
				ancestorsBreakpointsData={{}}
			/>
		</ComponentCssContext.Provider>
	)
}
